// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-preslite-js": () => import("./../../../src/pages/de/preslite.js" /* webpackChunkName: "component---src-pages-de-preslite-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-pricelist-js": () => import("./../../../src/pages/en/pricelist.js" /* webpackChunkName: "component---src-pages-en-pricelist-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-pricelist-js": () => import("./../../../src/pages/hr/pricelist.js" /* webpackChunkName: "component---src-pages-hr-pricelist-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-cennik-js": () => import("./../../../src/pages/pl/cennik.js" /* webpackChunkName: "component---src-pages-pl-cennik-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */)
}

